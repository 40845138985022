import "./styles/NotFound.css";


export default function NotFoundComponent() {

    return (
        <div className="not-found">
            <div>
                <h1 className="next-error-h1">404</h1>
                <div className="next-error-message">
                    <h2>This page could not be found.</h2>
                </div>
            </div>
        </div>
    );

}
