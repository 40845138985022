import React from "react";
import {Location, NavLink, Outlet, useLocation} from "react-router-dom";
import "./styles/Layout.css";


export default function LayoutComponent() {

    const location: Location = useLocation();

    return (
        <div className="alx-scroll-wrap">
            <div id="alx-preloader">
                <div className="cssload-thecube">
                    <div className="cssload-cube cssload-c1"></div>
                    <div className="cssload-cube cssload-c2"></div>
                    <div className="cssload-cube cssload-c4"></div>
                    <div className="cssload-cube cssload-c3"></div>
                </div>
            </div>
            <header>
                <div className="alx-logo">
                    <a href={location.pathname === '/' && location.hash !== '' ? process.env.REACT_APP_BASE_URL + '/#page1' : process.env.REACT_APP_BASE_URL + '/'}>
                        <img src="/images/logo.png" alt={process.env.REACT_APP_APPLICATION_NAME + ' Logo'}/>
                    </a>
                </div>
                <div className="alx-right-wrap">
                    <div className="alx-social-links">
                        <ul>
                            <li>
                                <span className="account-name"><i className="ti ti-user-check ti-20 mr-8"></i>Rizky Pratama</span>
                            </li>
                            <li>
                                <NavLink to={'https://wa.me/6285772723989/'} target="_blank">
                                    <i className="ti ti-brand-whatsapp ti-20"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'https://www.instagram.com/'} target="_blank">
                                    <i className="ti ti-brand-instagram ti-20"></i>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={'https://www.facebook.com/'} target="_blank">
                                    <i className="ti ti-brand-facebook ti-20"></i>
                                </NavLink>
                            </li>
                        </ul>
                    </div>
                    <span className="alx-burger"><span></span></span>
                </div>
            </header>
            <Outlet></Outlet>
            <aside className="alx-aside-sidebar alx-aside-sidebar__dark" id="alx-sidebar">
                <div className="alx-lang-change">
                    <ul>
                        <li><span className="language active">EN</span></li>
                        <li><span className="language">ID</span></li>
                    </ul>
                </div>
                <span className="alx-burger alx-burger__open"><span></span></span>
                <div className="alx-sidebar-widget">
                    <h5>HELLO THERE!</h5>
                    <img className="img-responsive" src="/images/person.jpg" alt={process.env.REACT_APP_APPLICATION_NAME + ' Profile Picture'}/>
                    <p>
                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut
                        labore et dolore magna aliqua. Ut enim ad minim veniam.
                    </p>
                </div>
                <hr/>
                <div className="alx-sidebar-menu">
                    <ul>
                        <li>
                            <a href={location.pathname === '/' && location.hash !== '' ? process.env.REACT_APP_BASE_URL + '/#page1' : process.env.REACT_APP_BASE_URL + '/'}>Home</a>
                        </li>
                    </ul>
                </div>
                <div className="alx-social-links__2">
                    <ul>
                        <li><a href="#"><i className="fa fa-twitter"></i></a></li>
                        <li><a href="#"><i className="fa fa-instagram"></i></a></li>
                        <li><a href="#"><i className="fa fa-facebook"></i></a></li>
                        <li><a href="#"><i className="fa fa-github"></i></a></li>
                    </ul>
                </div>
                <div className="alx-copyright">
                    ©&nbsp;{new Date().getFullYear()}
                    &nbsp;<a href={process.env.REACT_APP_BASE_URL + '/'}>{process.env.REACT_APP_COMPANY_NAME}</a>
                </div>
            </aside>
            <div className="alx-aside-sidebar__overlay"></div>
            <div className="alx-lang-change">
                <ul>
                    <li><span className="language active">EN</span></li>
                    <li><span className="language">ID</span></li>
                </ul>
            </div>
            <div className="alx-copyright">
                <p>
                    <span>©</span>{new Date().getFullYear()}
                    &nbsp;<a href={process.env.REACT_APP_BASE_URL + '/'}>{process.env.REACT_APP_COMPANY_NAME}</a>
                </p>
            </div>
        </div>
    );

}
