import React from "react";
import {createBrowserRouter, createRoutesFromElements, Route, RouterProvider} from "react-router-dom";
import "./App.css";
import NotFoundComponent from "./components/global/NotFoundComponent";
import IndexComponent from "./components/page/IndexComponent";
import LayoutComponent from "./components/page/LayoutComponent";


const router = createBrowserRouter(
    createRoutesFromElements(
        <Route>
            <Route path="/" element={<LayoutComponent></LayoutComponent>}>
                <Route index element={<IndexComponent></IndexComponent>}></Route>
            </Route>
            <Route path="*" element={<NotFoundComponent></NotFoundComponent>}></Route>
        </Route>
    )
)


export default function App() {
    return (
        <RouterProvider router={router}></RouterProvider>
    );
}
