import React from "react";


export default function IndexComponent() {

    return (
        <div id="alx-pagepiling">
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8">
                            <div className="alx-heading alx-text-center">
                                <div className="alx-heading__pretitle"><i className="fa fa-hand-peace-o"></i>Hello
                                    there
                                </div>
                                <h1 className="alx-heading__title">PRELOODE<br/>DIGITAL TEKNOLOGI</h1>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="alx-btns-section alx-text-center">
                                <a className="alx-btn" href="#page9">Start a project</a>
                                <a className="alx-btn alx-btn-primary" href="#page6">See my works</a>
                            </div>
                        </div>
                        <div className="col-lg-6 offset-lg-1"></div>
                    </div>
                </div>
                <div className="alx-canvas" id="alx-particles"></div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img jarallax" data-jarallax-video="https://www.youtube.com/watch?v=LcF6ut-1M94">
                <div className="alx-vertical-heading">
                    <i className="fa fa-question-circle-o"></i>Who i’m
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 order-lg-12">
                            <div className="alx-person-image alx-dots-style">
                                <img className="img-responsive alx-tilt" src="/images/person.jpg" alt=""/>
                                <h3 className="alx-person-image__title alx-text-center">6 Years of Experience</h3>
                            </div>
                        </div>
                        <div className="col-lg-6 alx-style" data-style="margin: 50px 0">
                            <div className="alx-heading">
                                <h2 className="alx-heading__title">About Me</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                                <div className="alx-heading__description">I have much experience in developing
                                    landing pages, online
                                    stores, corporate sites, home pages; experience in developing profitable
                                    business on the example
                                    of my clients, I know a lot of nuances that must a web site contain, and the
                                    elements, which
                                    significantly affect the conversion.
                                </div>
                                <div className="alx-heading__pretitle alx-style" data-style="margin-top: 20px">
                                    <i className="fa fa-diamond"></i>My code always High Quality, Hand-Coded
                                </div>
                            </div>
                            <a className="alx-btn" href="#page9">Become my client</a>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 alx-style" data-style="margin: 50px 0">
                            <div className="alx-heading">
                                <div className="alx-heading__pretitle"><i className="fa fa-diamond"></i>My
                                    Specializations
                                </div>
                                <h2 className="alx-heading__title">My Best Services</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                                <div className="alx-heading__description">In my work I using a variety of techniques
                                    and instruments
                                    like: HTML5, CSS3, Jquery, Responsive Design, Bootstrap Framework. I'm using the
                                    latest
                                    technologies and the latest trends in web development.
                                </div>
                            </div>
                            <a className="alx-btn" href="#page9">Start a project</a>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="alx-services alx-dots-style" data-interval="7000">
                                <div className="alx-services__container">
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main"><i className="fa fa-film"></i>
                                                <h4 className="alx-services__item__title">Video Editor</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-film"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main">
                                                <i className="fa fa-camera-retro"></i>
                                                <h4 className="alx-services__item__title">Photograther</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-camera-retro"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main">
                                                <i className="fa fa-diamond"></i>
                                                <h4 className="alx-services__item__title">Designer</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-diamond"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main"><i className="fa fa-feed"></i>
                                                <h4 className="alx-services__item__title">Manager</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-feed"></i></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="alx-services__container">
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main">
                                                <i className="fa fa-wordpress"></i>
                                                <h4 className="alx-services__item__title">WordPress</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-wordpress"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main"><i className="fa fa-css3"></i>
                                                <h4 className="alx-services__item__title">CSS&HTML</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-css3"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main">
                                                <i className="fa fa-opencart"></i>
                                                <h4 className="alx-services__item__title">OpenCart</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-opencart"></i></div>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <div className="alx-services__item__main">
                                                <i className="fa fa-connectdevelop"></i>
                                                <h4 className="alx-services__item__title">Support</h4>
                                            </div>
                                            <div className="alx-services__item__description">Lorem ipsum dolor sit
                                                amet consectetur
                                                adipisicing elit. Voluptates iusto magni eligendi eveniet veritatis.
                                            </div>
                                            <div className="alx-services__item__opacityicon">
                                                <i className="fa fa-connectdevelop"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="alx-vertical-heading"><i className="fa fa-code"></i>What i do</div>
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 alx-style" data-style="margin: 50px 0">
                            <div className="alx-heading">
                                <h2 className="alx-heading__title">My Skills</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                                <div className="alx-heading__description">
                                    I do my job well and on time - is unshakable principle is more important than
                                    which can not be even money.<br/><strong>Just check my portfolio and let's work
                                    together!</strong>
                                </div>
                            </div>
                            <div className="alx-skills-list">
                                <ul>
                                    <li><span>01</span>WordPress Intergation</li>
                                    <li><span>02</span>PSD TO HTML RESPONSIVE</li>
                                    <li><span>03</span>SEO OPTIMIZATION</li>
                                    <li><span>04</span>Fully Responsive design</li>
                                    <li><span>05</span>Promo Pages Designs</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 align-self-center">
                            <div className="alx-skills">
                                <div className="alx-skills__header">
                                    <div className="alx-skills__title">Logo / Branding</div>
                                    <div className="alx-skills__count">80%</div>
                                </div>
                                <div className="alx-skills__body">
                                    <div className="alx-skills__bar" data-skills="80%"></div>
                                </div>
                            </div>
                            <div className="alx-skills">
                                <div className="alx-skills__header">
                                    <div className="alx-skills__title">Coding</div>
                                    <div className="alx-skills__count">90%</div>
                                </div>
                                <div className="alx-skills__body">
                                    <div className="alx-skills__bar" data-skills="90%"></div>
                                </div>
                            </div>
                            <div className="alx-skills">
                                <div className="alx-skills__header">
                                    <div className="alx-skills__title">WordPress</div>
                                    <div className="alx-skills__count">70%</div>
                                </div>
                                <div className="alx-skills__body">
                                    <div className="alx-skills__bar" data-skills="70%"></div>
                                </div>
                            </div>
                            <div className="alx-skills">
                                <div className="alx-skills__header">
                                    <div className="alx-skills__title">HTML5 / CSS3</div>
                                    <div className="alx-skills__count">60%</div>
                                </div>
                                <div className="alx-skills__body">
                                    <div className="alx-skills__bar" data-skills="60%"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-5">
                            <div className="alx-heading">
                                <div className="alx-heading__pretitle"><i className="fa fa-money"></i>25$ my hourly
                                    rate
                                </div>
                                <h2 className="alx-heading__title">Prices</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">PROMO WEBSITES DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">CORPORATE WEBSITES DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">WEB SERVICES DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5 offset-lg-2 align-self-end">
                            <div className="alx-price">
                                <div className="alx-price__title">E-COMMERCE DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">SKETCHING & USER FLOW</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">HOMEPAGE DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                            <div className="alx-price">
                                <div className="alx-price__title">INNER PAGE DESIGN</div>
                                <div className="alx-price__price"><span>From</span>
                                    <p>900$</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="alx-vertical-heading"><i className="fa fa-rocket"></i>My Latest best Works</div>
                <div className="container">
                    <div className="col-lg-12">
                        <ul className="alx-portfolio-control alx-portfolio__nav alx-text-left">
                            <li className="alx-active" data-filter="*">All</li>
                            <li data-filter=".alx-f-branding">Branding</li>
                            <li data-filter=".alx-f-web">Web</li>
                            <li data-filter=".alx-f-graphic">Graphic</li>
                            <li data-filter=".alx-f-logo">Logo</li>
                            <li data-filter=".alx-f-photography"> Photography</li>
                        </ul>
                    </div>
                    <div className="alx-portfolio-items alx-layout-isotope alx-isotope-gap" data-grid="4">
                        <div className="alx-isotope-item alx-f-branding alx-f-photography">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-1.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-1.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>CREATIVE WEB DESIGN</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-graphic">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-2.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-2.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>NEW SITE DESIGN</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-web">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-3.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-3.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>COMPANY LOGO MOCKUP</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-logo alx-f-photography">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-4.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-4.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>NEW SITE DESIGN</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-branding">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-5.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-5.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>SIMPLE CURVES</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-web">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-6.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-6.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>MODERN APPROACH</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-branding">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-7.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-7.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>BRUSHES PRESET</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                        <div className="alx-isotope-item alx-f-graphic alx-f-web alx-f-photography">
                            <div className="alx-portfolio-item alx-portfolio-item__style1">
                                <a className="alx-img-gallery" href="/images/portfolio/portfolio-8.jpg"></a>
                                <div className="alx-portfolio-item-image">
                                    <div className="alx-bg-img" data-image="/images/portfolio/portfolio-8.jpg"></div>
                                </div>
                                <div className="alx-portfolio-item-details">
                                    <h4>INSPIRABLE WEBSITE</h4>
                                    <p>Creative</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="alx-vertical-heading"><i className="fa fa-commenting-o"></i>Clients say about me
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="alx-heading">
                                <h2 className="alx-heading__title">Testimonials</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                        <div className="alx-testimonials">
                            <div className="alx-testimonials__slides">
                                <div className="alx-testimonials__item">
                                    <img src="/images/review/review-1.jpg" alt=""/>
                                    <div className="alx-testimonials__content">
                                        <div className="alx-testimonials__item__review">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star-half-o"></i>
                                        </div>
                                        <div className="alx-testimonials__item__name">Sean Kline</div>
                                        <div className="alx-testimonials__item__text">
                                            The developer of this theme is flexible. There were some bugs that
                                            needed to be fixed. He fixed it fast and efficiently. I even had some
                                            extra suggestions (request) about the theme
                                        </div>
                                    </div>
                                </div>
                                <div className="alx-testimonials__item">
                                    <img src="/images/review/review-2.jpg" alt=""/>
                                    <div className="alx-testimonials__content">
                                        <div className="alx-testimonials__item__review">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                        </div>
                                        <div className="alx-testimonials__item__name">Tessa Winter</div>
                                        <div className="alx-testimonials__item__text">
                                            Fell in love with the design at first sight. Amazing work. I also want
                                            to express my great gratitude for the support and help in setting up. I
                                            also helped add more features! Thank you so much!
                                        </div>
                                    </div>
                                </div>
                                <div className="alx-testimonials__item">
                                    <img src="/images/review/review-3.jpg" alt=""/>
                                    <div className="alx-testimonials__content">
                                        <div className="alx-testimonials__item__review">
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star"></i>
                                            <i className="fa fa-star-half-o"></i>
                                        </div>
                                        <div className="alx-testimonials__item__name">Jolie Salinas</div>
                                        <div className="alx-testimonials__item__text">
                                            Great customer support. Had a minor issue and it was resolved by the
                                            support team within 24 hours. Highly recommend purchasing this theme.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="alx-slider__nav">
                                <div className="alx-slider__nav__prev">
                                    <div className="alx-slider__nav__next__icon">
                                        <i className="fa fa-angle-double-left"></i>
                                    </div>
                                </div>
                                <div className="alx-slider__nav__dots"></div>
                                <div className="alx-slider__nav__next">
                                    <div className="alx-slider__nav__next__icon">
                                        <i className="fa fa-angle-double-right"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="alx-vertical-heading">
                    <i className="fa fa-heart-o"></i>Expensive
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5 alx-style" data-style="margin: 50px 0">
                            <div className="alx-heading">
                                <h2 className="alx-heading__title">My Clients</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                                <div className="alx-heading__description">
                                    I'm seize every opportunity to develop long term business partnerships with
                                    companies who are leaders in their sector. I'm work closely with our network
                                    partners in order to deliver the best solutions available to our clients.
                                </div>
                            </div>
                            <a className="alx-btn" href="#">become my client</a>
                        </div>
                        <div className="col-lg-6 offset-lg-1">
                            <div className="alx-services alx-services__partners alx-dots-style" data-interval="7000">
                                <div className="alx-services__container">
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-3.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-4.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-5.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-6.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="alx-services__container">
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-6.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-2.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-4.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-5.png" alt=""/>
                                        </div>
                                    </div>
                                    <div className="alx-services__item">
                                        <div className="alx-services__item__wrap">
                                            <img src="/images/partner/partner-3.png" alt=""/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="alx-vertical-middle alx-white-section pp-scrollable alx-bg-img" data-image="/images/background/background.png">
                <div className="alx-vertical-heading">
                    <i className="fa fa-bell-o"></i>Contact with me
                </div>
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-5">
                            <div className="alx-heading">
                                <h2 className="alx-heading__title">Say Hello</h2>
                                <div className="alx-heading__zigzag">
                                    <svg x="0px" y="0px" viewBox="0 0 100 19.5">
                                        <g>
                                            <polygon points="79.3,18 69.6,8.3 60,18 50.3,8.3 40.6,18 31,8.3 21.3,18 11.7,8.3 3.4,16.5 0.6,13.7 11.7,2.7 21.3,12.3 31,2.7 								40.6,12.3 50.3,2.7 60,12.3 69.6,2.7 79.3,12.3 88.9,2.7 100,13.6 97.2,16.4 89,8.3 "></polygon>
                                        </g>
                                    </svg>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4">
                            <div className="alx-icon">
                                <div className="alx-icon__icon">
                                    <span><i className="fa fa-phone"></i></span>
                                </div>
                                <div className="alx-icon__text">
                                    <h5>Call Me Anytime</h5>
                                    <p>+754-554-54-34</p>
                                </div>
                            </div>
                            <div className="alx-icon">
                                <div className="alx-icon__icon">
                                    <span><i className="fa fa-envelope"></i></span>
                                </div>
                                <div className="alx-icon__text">
                                    <h5>Email me</h5>
                                    <a href="/cdn-cgi/l/email-protection#e68494878f888f878d928e838b83a6818b878f8ac885898b"><span className="__cf_email__" data-cfemail="105f767679737550747f7d79717e3e737f7d">[email&#160;protected]</span></a>
                                </div>
                            </div>
                            <div className="alx-icon">
                                <div className="alx-icon__icon">
                                    <span><i className="fa fa-home"></i></span>
                                </div>
                                <div className="alx-icon__text">
                                    <h5>Address</h5>
                                    <p>445 Mount Eden Road, Mount</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-8">
                            <form className="alx-contact-form" action="#">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <div className="alx-input-group">
                                            <input name="name" type="text" required placeholder="Your Name *"/>
                                            <i className="fa fa-user-o"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="alx-input-group">
                                            <input name="name" type="email" required placeholder="Your Email *"/>
                                            <i className="fa fa-at"></i>
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="alx-input-group">
                                            <textarea name="name" rows={6} placeholder="Message *" required/>
                                            <i className="fa fa-commenting-o"></i>
                                        </div>
                                        <div className="alx-success-msg hide">
                                            <p>Message has been send successfully</p>
                                        </div>
                                        <input className="alx-btn" type="submit" value="Send a Massage"/>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );

}
